import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";
export const useStyles = createUseStyles({
  header: {
    padding: "20px 0 50px 0",
  },
  headerContainer: {
    background: "var(--whiteOpacity01)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 50px 0px var(--blackOpacity20)",
    border: "1px solid var(--whiteOpacity10)",
    [mediaQuery.SCREEN_SM_MAX]: {
      padding: "15px",
      "& .ant-row": {
        gap:"15px",
        justifyContent:"center",
      },
    },
    "& img": {
      [mediaQuery.SCREEN_SM_MAX]: {
        maxWidth: "150px",
      },
    },
    "& .referralLink": {
      transition: "0.2s all ease-in-out",
      color: "var(--white)",

      "&:hover": {
        color: "var(--cText)",
      },
    },
  },
});
