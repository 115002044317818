import { useEffect, useState } from "react";
import { Spin } from 'antd';
import type { ColumnsType } from "antd/es/table";
import { useWallet } from "@solana/wallet-adapter-react";
import { myInvestment } from "apiServices/app";
import { getStorageData, parsedWithFixed, pubKeyRedirect, shortenAddress, signerRedirect, toastMessage } from "utils/helper";
import TableComponent from "components/FormsComponent/TableComponent";
import { KeyIcon } from "svgIcon";

interface DataType {
  id: number;
  actual_coin: any
  invested_amount: any;
  member_public_key: string;
  member_signer: string;
  reffered_by: any;
  coin_type: any;
  user_id: number;
}

const InvestmentListComponent = () => {
  const getLocalAuth = getStorageData("auth-token");
  const { publicKey, sendTransaction } = useWallet();
  const [loader, setLoader] = useState(false);
  const [investmentList, setInvestmentList] = useState<any>([]);

  const onFetchMyInvestment = () => {
    setLoader(true);
    try {
      const rawJson = {
        auth_code: getLocalAuth,
        wallet_address: publicKey,
      };
      myInvestment(rawJson)
        .then((response) => {
          const responseData = response as any;
          if (responseData && responseData.user_investments) {
            setInvestmentList(responseData.user_investments.reverse());
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toastMessage(error);
        });
    } catch (error) {
      setLoader(false);
      toastMessage(error);
    }
  };

  useEffect(() => {
    getLocalAuth !== null && onFetchMyInvestment();
  }, [getLocalAuth]);

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: item.id,
          id: <span className='tId'>{item.id}</span>,
          actual_coin: <span className='tAmount'>{item.actual_coin && item.actual_coin} {item.coin_type == 1 ? "SOL" : "USDC"}</span>,
          member_public_key: <a href={`${signerRedirect}${item.member_public_key}`} target="_blank" className='tSigner'>{item.member_public_key && shortenAddress(item.member_public_key)}</a>,
          member_signer: <a href={`${pubKeyRedirect}${item.member_signer}`} target="_blank" className='tPubKey'><KeyIcon />{item.member_signer && shortenAddress(item.member_signer)}</a>,
        };
      })
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Invested Amount",
      dataIndex: "actual_coin",
      key: "actual_coin",
    },
    {
      title: "Signer",
      dataIndex: "member_public_key",
      key: "member_public_key",
    },
    {
      title: "Public Key",
      dataIndex: "member_signer",
      key: "member_signer",
    },
  ];
  return (
    <div>
      {loader ? <Spin /> : (
        <TableComponent
          data={responseFormate(investmentList)}
          columns={columns}
          scroll={{ y: 295 }}
        />
      )}
    </div>
  );
};

export default InvestmentListComponent;
