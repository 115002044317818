export const EncryptionPassword =
  "7b67ff8e6a67b866d898a128fe199da657d197107f2f2c4dfc6187cc61fabb4d";

async function encrypt(text: string, password: string): Promise<string> {
  const encoder = new TextEncoder();
  const encodedText = encoder.encode(text);
  const passwordBuffer = encoder.encode(password);
  const keyBuffer = await crypto.subtle.digest("SHA-256", passwordBuffer);
  const key = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encodedText
  );
  const encryptedArray = new Uint8Array(encrypted);
  const result = new Uint8Array(iv.length + encryptedArray.length);
  result.set(iv);
  result.set(encryptedArray, iv.length);
  return btoa(String.fromCharCode.apply(null, Array.from(result)));
}

async function decrypt(
  encryptedText: string,
  password: string
): Promise<string> {
  const decoder = new TextDecoder();
  const encryptedArray = new Uint8Array(
    atob(encryptedText)
      .split("")
      .map((c) => c.charCodeAt(0))
  );
  const iv = encryptedArray.slice(0, 12);
  const encryptedData = encryptedArray.slice(12);
  const passwordBuffer = new TextEncoder().encode(password);
  const keyBuffer = await crypto.subtle.digest("SHA-256", passwordBuffer);
  const key = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );
  const decrypted = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encryptedData
  );
  return decoder.decode(decrypted);
}

export async function encryptObject(
  obj: any,
  password: string
): Promise<string> {
  const text = JSON.stringify(obj);
  return encrypt(text, password);
}

export async function decryptObject(
  encryptedText: string,
  password: string
): Promise<any> {
  const decryptedText = await decrypt(encryptedText, password);
  return JSON.parse(decryptedText);
}
