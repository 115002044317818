import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";
export const useStyles = createUseStyles({
  mainContainer: {
    "& .heroWrapper": {
      paddingBottom: "150px",
      "& .container": {
        "&> .ant-row": {
          alignItems:"start",
          [mediaQuery.SCREEN_XL_MAX]: {
            flexDirection: "column",
          },
          "&> .ant-col": {
            [mediaQuery.SCREEN_XL_MAX]: {
              width: "80%",
              maxWidth: "80%",
            },
            [mediaQuery.SCREEN_MD_MAX]: {
              width: "100%",
              maxWidth: "100%",
            },
          },
        },
      },
      "& .card": {
        [mediaQuery.SCREEN_SM_MAX]: {
          marginBottom: "15px",
        },
        "& .cText": {
          color: "var(--light)",
        },
        "& .linkText": {},
        "& .svgIcon": {
          cursor: "pointer",
          marginLeft: "8px",
          "& path": {
            fill: "var(--white)",
          },
        },
      },
      "& .formFields": {
        padding: "30px 0",
        "& label": {
          marginBottom: "5px",
          fontSize: "16px",
          color: "var(--light)",
          display: "inline-block",
        },
        "& mb0": {
          marginBottom: "0px",
        },
      },
    },
    "& .card": {
      padding: "25px",
      [mediaQuery.SCREEN_SM_MAX]: {
        minHeight: "unset",
        padding: "20px",
      },
    },
    "& .partnerSection": {
      "& .container": {
        [mediaQuery.SCREEN_LG_MAX]: {
          maxWidth: "100%",
          width: "100%",
        },
      },
      "& .sBody": {
        "&> div": {
          [mediaQuery.SCREEN_LG_MAX]: {
            gap: "10px",
            flexWrap: "wrap",
          },
        },
        "& img": {
          maxWidth: "16%",
          width: "16%",
          transition: "0.2s all ease-in-out",
          [mediaQuery.SCREEN_LG_MAX]: {
            maxWidth: "32%",
            width: "32%",
            flexGrow: "1",
          },
          [mediaQuery.SCREEN_SM_MAX]: {
            maxWidth: "48%",
            width: "48%",
          },

          "&:hover": {
            boxShadow: "inset var(--whiteOpacity10) 0px 0px 10px 0px",
          },
        },
      },
    },
    "& .aboutSection": {
      "& .aboutContainer": {
        "& .aboutWrapper": {
          "&> .ant-row": {
            [mediaQuery.SCREEN_LG_MAX]: {},
            "&> .ant-col": {
              [mediaQuery.SCREEN_LG_MAX]: {
                maxWidth: "100%",
                width: "100%",
              },
            },
          },
          "& .lgHeading": {
            fontSize: "36px",
          },
          "& .aboutImg": {
            position: "relative",
            "& img": {
              width: "100%",
              [mediaQuery.SCREEN_LG_MAX]: {
                marginTop: "50px",
              },
            },
            "& .blurBgCircle": {
              content: "''",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              maxWidth: "600px",
              width: "100%",
              height: "600px",
              background: "var(--primary)",
              opacity: "0.1",
              filter: "blur(70px)",
              borderRadius: "50%",
              zIndex: "-1",
            },
            "& .blurShadow": {
              content: "''",
              position: "absolute",
              bottom: "-20%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              maxWidth: "700px",
              width: "100%",
              height: "5px",
              background:
                "linear-gradient(to right,transparent,var(--primary),transparent)",
              opacity: "0.5",
              filter: "blur(5px)",
              borderRadius: "50%",
              zIndex: "-1",
            },
            "& .playButton": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "90px",
              height: "90px",
              backgroundColor: "var(--purple)",
              borderRadius: "50%",
              zIndex: "2",
              transition: "0.2s all ease-in-out",
              cursor: "pointer",
              [mediaQuery.SCREEN_MD_MAX]: {
                width: "60px",
                height: "60px",
                transform: "translate(-50%,-25%)",
              },
              "&:hover": {
                backgroundColor: "var(--darkPink)",
              },
              "& svg": {
                marginLeft: "5px",
                display: "flex",
                [mediaQuery.SCREEN_MD_MAX]: {
                  width: "16px",
                  height: "16px",
                  marginLeft: "3px",
                },
              },
            },
          },
        },
      },
    },
    "& .logoSection": {
      "& .sBody": {
        padding: "50px 0",
        background:
          "linear-gradient(to left,var(--background),var(--whiteOpacity05),var(--background))",
        "& img": {
          transition: "0.3s all ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        },
      },
    },
    "& .tokenomicsSection": {
      "& .ant-row": {
        [mediaQuery.SCREEN_MD_MAX]: {
          flexDirection: "column",
          "& .ant-col": {
            width: "100%",
            maxWidth: "100%",
            flex: "0 0 100%",
          },
        },
        "& .tokenL": {
          "& img": {
            width: "100%",
            [mediaQuery.SCREEN_MD_MAX]: {
              marginBottom: "50px",
              width: "80%",
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
            },
          },
        },
        "& .card": {
          padding: "10px 15px",
          borderRadius: "10px",
          borderLeft: "5px solid var(--secondary)",
          transition: "0.3s all ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
          "& .left": {
            "& .smHeading": {
              color: "var(--cText)",
            },
            "& .cText": {
              fontSize: "18px",
              color: "var(--white)",
              fontWeight: "600",
              "& span": {
                fontSize: "16px",
                fontWeight: "300",
                marginLeft: "5px",
              },
            },
          },
          "& .right": {
            textAlign: "right",
            "& .cText": {
              color: "var(--white)",
            },
          },
          "&.first": {
            borderLeft: "3px solid #F28630",
          },
          "&.second": {
            borderLeft: "3px solid #9695F2",
          },
          "&.third": {
            borderLeft: "3px solid #24D715",
          },
          "&.fourth": {
            borderLeft: "3px solid #FF3D3D",
          },
          "&.fifth": {
            borderLeft: "3px solid #A900D7",
          },
          "&.sixth": {
            borderLeft: "3px solid #00B8FF",
          },
          "&.seventh": {
            borderLeft: "3px solid #00D3A1",
          },
        },
      },
    },
    "& .vestingSection": {
      "& .ant-row": {
        [mediaQuery.SCREEN_MD_MAX]: {
          marginBottom: "0px",
        },
        "& .ant-col": {
          [mediaQuery.SCREEN_MD_MAX]: {
            marginBottom: "20px",
          },
          "& .card": {
            padding: "15px 25px",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
            transition: "0.2s all ease-in-out",
            "&:hover": {
              boxShadow: "none",
              border: "1px solid var transparent",
            },
            "& .cardIcon": {
              "& svg": {
                animation: "moveLR 0.5s infinite ease-in-out",
                "& path": {
                  fill: "var(--secondary)",
                },
              },
            },
          },
        },
      },
    },
    "& .participateSection": {
      "& .ant-row": {
        [mediaQuery.SCREEN_MD_MAX]: {
          marginBottom: "0px",
        },
        "& .ant-col": {
          [mediaQuery.SCREEN_MD_MAX]: {
            marginBottom: "20px",
          },
          "& .card": {
            textAlign: "center",
            padding: "20px",
            borderRadius: "10px",
            width: "100%",
            height: "100%",
            transition: "0.2s all ease-in-out",
            "&:hover": {
              boxShadow: "none",
              border: "1px solid var transparent",
            },
            "& .cardContent": {},
            "& .cardIcon": {
              "& svg": {
                "& path": {
                  fill: "var(--secondary)",
                },
              },
            },
          },
        },
      },
    },
    "& .exampleSection": {
      "& .sBody": {
        "&> .ant-row": {
          "&:nth-child(even)": {
            flexDirection: "row-reverse",
          },
          "&> .ant-col": {
            "&> img": {
              [mediaQuery.SCREEN_LG_MAX]: {
                width: "80%",
              },
              [mediaQuery.SCREEN_MD_MAX]: {
                width: "65%",
                marginTop: "50px",
              },
            },
          },
        },
        "& .steps": {
          width: "75%",
          margin: "0 auto",
          "& .left": {
            "& p": {
              color: "var(--secondary)",
            },
          },
        },
        "& .egBottom": {
          marginTop: "100px",
          "& .card": {
            padding: "20px",
            [mediaQuery.SCREEN_MD_MAX]: {
              marginBottom: "20px",
            },
            "& p": {
              "& span": {
                color: "var(--secondary)",
                fontWeight: "600",
              },
            },
            "&> span": {
              color: "var(--cText)",
              fontWeight: "400",
            },
          },
          "& .egNote": {
            padding: "10px",
            width: "fit-content",
            marginTop: "20px",
            border: "1px dashed var(--whiteOpacity10)",
            borderRadius: "5px",
            [mediaQuery.SCREEN_MD_MAX]: {
              marginTop: "0px",
            },
            "& p": {
              color: "var(--secondary)",
              fontWeight: "300",
              "& span": {
                fontWeight: "500",
              },
            },
          },
        },
      },
    },
    "& .featureSection": {
      "& .card": {
        padding: "30px",
        marginTop: "90px",
        minHeight: "200px",
        [mediaQuery.SCREEN_LG_MAX]: {
          marginTop: "70px",
          minHeight: "unset",
        },
        "& .cardIcon": {
          marginTop: "-90px",
          width: "125px",
          height: "125px",
          borderRadius: "50%",
          backgroundColor: "var(--purple)",
          border: "10px solid var(--background)",
          "& svg": {
            "& path": {
              fill: "var(--white)",
            },
          },
        },
        "& p": {
          color: "var(--white)",
          fontWeight: "500",
          marginTop: "30px",
          [mediaQuery.SCREEN_LG_MAX]: {
            marginTop: "10px",
          },
        },
      },
    },
    "& .benefitSection": {
      "& .item": {
        [mediaQuery.SCREEN_LG_MAX]: {
          marginBottom: "50px",
        },
      },
      "& .right": {
        "& .card": {
          padding: "50px",
          [mediaQuery.SCREEN_LG_MAX]: {
            padding: "30px",
            textAlign: "left",
          },
          "& .cText": {
            [mediaQuery.SCREEN_LG_MAX]: {
              marginBottom: "30px",
            },
          },
        },
      },
    },
    "& .structureSection": {
      "& .item": {
        [mediaQuery.SCREEN_LG_MAX]: {
          marginBottom: "50px",
        },
      },
      "& .right": {
        "& .card": {
          padding: "30px",
          "& .lgHeading": {
            color: "var(--secondary)",
          },
        },
      },
    },
  },
});
