import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SiteEnableComponent from 'components/SiteEnableComponent';
import SolanaProivder from "provider/SolanaProvider";
import "./assets/common.css";

// const { networkConfig } = createNetworkConfig({
//   testnet: { url: getFullnodeUrl("testnet") },
//   mainnet: { url: getFullnodeUrl("mainnet") },
// });

function App() {
  return (
    <Router>
      <SolanaProivder>
        <SiteEnableComponent />
      </SolanaProivder>
    </Router>
  );
}

export default App;
