import { useEffect, useState } from 'react'
import { useWallet } from "@solana/wallet-adapter-react";
import { publicKey } from "@metaplex-foundation/umi";
import { userInvestment } from "apiServices/app";
import { checkAllRequiredFieldsWithKey, identifyCoinAmount, toastMessage } from "utils/helper";
import { EncryptionPassword, encryptObject } from 'utils/encryptionDecryption';
import { allocatedPPayAmount, minAmountUSDC } from "utils/env";
import { handleTransfer } from 'utils/handleTransfer';
import { formField, requiredField } from '../RoundsComponent/constant'
// import SelectComponent from 'components/FormsComponent/SelectComponent'
import ModalComponent from 'components/FormsComponent/ModalComponent';
import TextBoxComponent from 'components/FormsComponent/TextBoxComponent'
import ButtonComponent from 'components/FormsComponent/ButtonComponent';
import TxnStatusComponent from '../TxnStatusComponent';

const StepOneComponent = ({
    dataContent,
    setHaveToReload
}: any) => {
    const wallet = useWallet();
    const { connected, disconnect } = useWallet();
    const [digest, setDigest] = useState("");
    const [txnError, setTxnError] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const [showTxnModal, setShowTxnModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState("SOL");
    const [errors, setErrors] = useState<any>();
    const [manageChainAmount, setManageChainAmount] = useState({
        solana: 0,
        solanaUSDC: 0,
    });
    const [formData, setFormData] = useState({
        ...formField,
    });

    const checkAllErrors = () => {
        let errorObject = {
            ...checkAllRequiredFieldsWithKey(requiredField, formData),
        };
        setErrors({ ...errors, ...errorObject });
        return Object.values(errorObject).every((errorMsg) => !errorMsg);
    };

    const handleAmount = ({ target: { name, value } }: any) => {
        if (value !== " " && !/^\d*\.?\d*$/.test(value)) {
            setErrors({
                ...errors,
                [name]: "Amount should be numeric",
            });
            return;
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const removeError = ({ target: { name } }: any) => {
        setErrors({ ...errors, [name]: "" });
    };

    function handlePaste(event: any) {
        event.preventDefault(); // Prevent the default paste behavior
    }

    const onApiStore = async (getSignature: any) => {
        setDigest(getSignature);
        const rawJson = {
            auth_code: dataContent.auth_code,
            invested_amount: formData.invested_amount,
            member_public_key: getSignature,
            member_signer: publicKey(wallet.publicKey!),
            coin_type: selectedItems == "SOL" ? 1 : 2,
            actual_coin_amount: parseFloat(formData.amount)
        };
        const enctyptData = await encryptObject(rawJson, EncryptionPassword);
        userInvestment({
            raw_data: enctyptData
        })
            .then((result) => {
                setFormData({ ...formField });
                setSelectedItems("SOL");
                setHaveToReload(true);
                setBtnLoading(false);
            })
            .catch((error) => {
                setBtnLoading(false);
                if (error && error.message) {
                    toastMessage(error.message);
                }
            });
    }

    const validateAmount = (selectedItems: any, amountStr: any) => {
        const amount = parseFloat(amountStr);
        if (selectedItems === "SOL") {
            const requiredAmount = (minAmountUSDC / manageChainAmount.solana).toFixed(3);
            const isValid = parseFloat(requiredAmount) <= amount;

            if (!isValid) {
                toastMessage(`Minimum amount should be ${requiredAmount} ${selectedItems}`);
                return false;
            }
        } else if (selectedItems === "SOL-USDC") {

            if (amount < minAmountUSDC) {
                toastMessage(`Minimum amount should be ${minAmountUSDC} ${selectedItems}`);
                return false;
            }
        }
        return true;
    };

    const handleTransferBySelection = async (selectedItems: any, amount: any) => {
        const transferType = selectedItems === "SOL" ? "sol" : "usdc";
        await handleTransfer(amount, transferType, setTxnError, wallet, setBtnLoading, setShowTxnModal, onApiStore);
    };

    const createLaunch = async () => {
        if (!connected) {
            toastMessage("Please first connect ur wallet!")
            return;
        }
        if (checkAllErrors()) {
            const isValid = validateAmount(selectedItems, formData.amount);
            if (!isValid) return;
            setDigest("");
            setTxnError("");
            await handleTransferBySelection(selectedItems, formData.amount);
        }
    };

    const onModalClose = () => {
        setDigest("");
        setTxnError("");
        setBtnLoading(false);
        setShowTxnModal(false);
    }

    useEffect(() => {
        identifyCoinAmount(setManageChainAmount);
    }, []);

    useEffect(() => {
        if (selectedItems && manageChainAmount.solana !== 0 && manageChainAmount.solanaUSDC !== 0) {
            const getCoinAmount = selectedItems === "SOL" ? manageChainAmount.solana : manageChainAmount.solanaUSDC;
            const getInvestedAmount = (parseFloat(formData.amount) * getCoinAmount);
            const getAllocatedAmount = getInvestedAmount / allocatedPPayAmount;
            setFormData({
                ...formData,
                coin_amount: getCoinAmount,
                invested_amount: getInvestedAmount,
                allocated_ppay: getAllocatedAmount
            });
        }
    }, [selectedItems, formData.amount]);

    return (
        <>
            <div className="formFields  dFlex gap10  flexCol gap20">
                <div className='tabsWrapper'>
                    <label>Select Coin*</label>
                    <div className='tabs dFlex gap10 alignCenter'>
                        <p
                            className={selectedItems === "SOL" ? "tab cText active" : "tab cText"}
                            onClick={() => setSelectedItems("SOL")}
                        >
                            SOL
                        </p>
                        <p
                            className={selectedItems === "SOL-USDC" ? "tab cText active" : "tab cText"}
                            onClick={() => setSelectedItems("SOL-USDC")}
                        >
                            SOL-USDC
                        </p>
                    </div>
                </div>
                <TextBoxComponent
                    type="text"
                    title="Amount"
                    label="Amount"
                    extraClass="mb0"
                    mandatory
                    name="amount"
                    onFocus={removeError}
                    onChange={handleAmount}
                    onPaste={handlePaste}
                    value={formData && formData.amount}
                    error={errors && errors.amount}
                />
                <p className='cText'>Allocated $PAY : <strong>{Number.isNaN(formData.allocated_ppay) ? 0 : formData.allocated_ppay.toFixed(3)}</strong></p>
            </div>
            <ButtonComponent
                onClick={() => !btnLoading && createLaunch()}
                extraClass="chainKey"
            >
                {btnLoading ? "Loading..." : "Invest Now"}
            </ButtonComponent>
            <ModalComponent
                footer=""
                openable={showTxnModal}
                isCloseIcon={false}
                widthStyle={520}
                extraClass="modalWhite"
            >
                <TxnStatusComponent digest={digest} txnError={txnError} onModalClose={onModalClose} />
            </ModalComponent>
        </>
    )
}

export default StepOneComponent