import React from 'react'

const NotFoundComponent = () => {
    return (
        <div className='notification'>
            <div className='nText'>
                $PAY will be available on M3M3 by Meteora 16th Jan
            </div>
        </div>
    )
}

export default NotFoundComponent