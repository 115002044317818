import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { getIsEnable } from 'apiServices/app';
import { toastMessage } from 'utils/helper';
import BodyComponent from 'components/Layout/BodyComponent';
import NotFoundComponent from 'components/NotFoundComponent';
import { log } from 'console';

const SiteEnableComponent = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [siteStep, setSiteStep] = useState(0);

    const onGetIsSiteEnable = () => {
        setShowLoader(true);
        try {
            getIsEnable()
                .then((response) => {
                    const responseData = response.data || response;
                    const getValue = responseData.isSiteEnabled == false ? 2 : 1;
                    setSiteStep(getValue);
                    setShowLoader(false);
                   
                }).catch((error) => {
                    setShowLoader(false);
                    toastMessage(error);
                });
        } catch (error: any) {
            setShowLoader(false);
            toastMessage(error);
        }
    }

    useEffect(() => {
        onGetIsSiteEnable();
    }, [])

    return (
        <> 
            {showLoader ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            ) : (
                <>
                    {siteStep == 1 && <BodyComponent />}
                    {siteStep == 2 && <NotFoundComponent />}
                </>
            )}
        </>
    )
}

export default SiteEnableComponent