import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";

export const useStyles = createUseStyles({
  mainInformationWrapper: {
    [mediaQuery.SCREEN_XL_MAX]: {
      textAlign: "center",
    },
    "& .heroLabel": {
      "& p": {
        fontSize: "16px",
        letterSpacing: "4px",
        color: "var(--white)",
        backgroundColor: "var(--whiteOpacity01)",
        border: "1px solid var(--whiteOpacity10)",
        display: "inline-block",
        padding: "10px 15px",
        borderRadius: "30px",
        textTransform: "uppercase",
        boxShadow: "inset var(--whiteOpacity10) 0px 0px 10px 0px",
        [mediaQuery.SCREEN_XL_MAX]: {
          fontSize: "15px",
        },
        [mediaQuery.SCREEN_SM_MAX]: {
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          letterSpacing: "2px",
        },
        "& span": {
          fontWeight: "600",
        },
      },
    },
    "& .heroContent": {
      "& h1": {
        fontSize: "60px",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
        backgroundImage: "var(--lightGradient)",
        margin: "20px 0 40px",
        textTransform: "uppercase",
        fontFamily: "'Good Times', sans-serif;",
        [mediaQuery.SCREEN_LG_MAX]: {
          fontSize: "40px",
          margin: "20px 0",
        },
        [mediaQuery.SCREEN_SM_MAX]: {
          fontSize: "8.5vw",
        },
      },
      "& span": {
        textTransform: "uppercase",
        letterSpacing: "5px",
        fontSize: "18px",
        lineHeight: "35px",
        color: "var(--white)",
        paddingLeft: "20px",
        borderLeft: "2px solid var(--secondary)",
        fontFamily: "'Good Times', sans-serif;",
        [mediaQuery.SCREEN_XL_MAX]: {
          paddingLeft: "unset",
          padding: "10px 0",
          borderLeft: "unset",
          borderTop: "1px solid var(--whiteOpacity10)",
          borderBottom: "1px solid var(--whiteOpacity10)",
          marginBottom: "30px",
        },
        [mediaQuery.SCREEN_SM_MAX]: {
          fontSize: "14px",
          lineHeight: "25px",
        },
      },
    },
    "& .heroPiechart": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
      padding: "20px",
      gap: "20px",
      [mediaQuery.SCREEN_SM_MAX]: {
        marginTop: "0px",
      },
      "& img": {
        width: "100%",
        maxWidth: "500px",
      },
    },
  },
});
