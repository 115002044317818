import React, { useState } from 'react';
import { Row, Col } from "antd";
import { Carousel } from 'antd';
import solanaLogo from "assets/images/logos/solana.svg";
import suiLogo from "assets/images/logos/sui.svg";
import blastLogo from "assets/images/logos/blast.svg";
import bnbLogo from "assets/images/logos/bnb.svg";
import polygonLogo from "assets/images/logos/Polygon.svg";
import tronLogo from "assets/images/logos/tron.svg";
import usdcLogo from "assets/images/logos/USDC.svg";
import tetherLogo from "assets/images/logos/tether.svg";
import bigBrainHoldingLogo from "assets/images/logos/partners/bigBrainHolding.svg";
import cogitentVenturesLogo from "assets/images/logos/partners/cogitentVentures.svg";
import riksCapitalLogo from "assets/images/logos/partners/riksCapital.svg";
import digitizedAssetValleyLogo from "assets/images/logos/partners/digitizedAssetValley.svg";
import chainLinkLogo from "assets/images/logos/partners/chainLink.svg";
import solsterLogo from "assets/images/logos/partners/solster.svg";
import seededLogo from "assets/images/logos/partners/seeded.svg";
import bonfidaLogo from "assets/images/logos/partners/bonfida.svg";
import cropperFertilizerLogo from "assets/images/logos/partners/cropperFertilizer.svg";
import daoLaunchLogo from "assets/images/logos/partners/daoLaunch.svg";
import zebecLogo from "assets/images/logos/partners/zebec.svg";
import daoStarterLogo from "assets/images/logos/partners/daoStarter.svg";
import cryptoInLogo from "assets/images/logos/partners/cryptoIn.svg";
import iqProtocolLogo from "assets/images/logos/partners/iqProtocol.svg";
import aboutSection from "assets/images/aboutSection_.png";
import pieChart from "assets/images/chart____.svg";
import {
    RightarrowIcon,
    PlayButtonIcon,
    BopGrowthIcon,
    BopCommunityIcon,
    BopAirdropIcon,
    BopEventIcon,
    BopLockIcon,
    FeaturePOSIcon,
    FeatureBlockchainIcon,
    FeatureEcoIcon,
    FeatureInsightsIcon
} from "svgIcon";
import ModalComponent from 'components/FormsComponent/ModalComponent';

const DashContentComponent = () => {
    const logos = [
        { src: solanaLogo, alt: "solana Logo" },
        { src: suiLogo, alt: "sui Logo" },
        { src: blastLogo, alt: "blast Logo" },
        { src: bnbLogo, alt: "bnb Logo" },
        { src: polygonLogo, alt: "polygon Logo" },
        { src: tronLogo, alt: "tron Logo" },
        { src: usdcLogo, alt: "usdc Logo" },
        { src: tetherLogo, alt: "tether Logo" }
    ];

    const [showPlayback, setShowPlayback] = useState(false);

    return (
        <>
            <div className="partnerSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Investors & Partners</p>
                    </div>
                    <div className="sBody">
                        <div className="dFlex gap10 flexWrap justifyCenter">
                            <img src={bigBrainHoldingLogo} alt="bigBrainHoldingLogo" />
                            <img src={cogitentVenturesLogo} alt="cogitentVenturesLogo" />
                            <img src={riksCapitalLogo} alt="riksCapitalLogo" />
                            <img src={digitizedAssetValleyLogo} alt="digitizedAssetValleyLogo" />
                            <img src={chainLinkLogo} alt="chainLinkLogo" />
                            <img src={solsterLogo} alt="solsterLogo" />
                            <img src={seededLogo} alt="seededLogo" />
                            <img src={bonfidaLogo} alt="bonfidaLogo" />
                            <img src={cropperFertilizerLogo} alt="cropperFertilizerLogo" />
                            <img src={daoLaunchLogo} alt="daoLaunchLogo" />
                            <img src={zebecLogo} alt="zebecLogo" />
                            <img src={daoStarterLogo} alt="daoStarterLogo" />
                            <img src={cryptoInLogo} alt="cryptoInLogo" />
                            <img src={iqProtocolLogo} alt="iqProtocolLogo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="partnerSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Investors & Partners</p>
                    </div>
                    <div className="sBody">
                        <div className="dFlex gap10 flexWrap justifyCenter">
                            <img src={bigBrainHoldingLogo} alt="bigBrainHoldingLogo" />
                            <img src={cogitentVenturesLogo} alt="cogitentVenturesLogo" />
                            <img src={riksCapitalLogo} alt="riksCapitalLogo" />
                            <img src={digitizedAssetValleyLogo} alt="digitizedAssetValleyLogo" />
                            <img src={chainLinkLogo} alt="chainLinkLogo" />
                            <img src={solsterLogo} alt="solsterLogo" />
                            <img src={seededLogo} alt="seededLogo" />
                            <img src={bonfidaLogo} alt="bonfidaLogo" />
                            <img src={cropperFertilizerLogo} alt="cropperFertilizerLogo" />
                            <img src={daoLaunchLogo} alt="daoLaunchLogo" />
                            <img src={zebecLogo} alt="zebecLogo" />
                            <img src={daoStarterLogo} alt="daoStarterLogo" />
                            <img src={cryptoInLogo} alt="cryptoInLogo" />
                            <img src={iqProtocolLogo} alt="iqProtocolLogo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutSection sectionSpacing">
                <div className="container">
                    <div className="aboutContainer">
                        <div className="aboutWrapper">
                            <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"middle"}>
                                <Col
                                    className="gutter-row"
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 12 }}>
                                    <p className="lgHeading">Digital assets are Tranforming finance. PocketPay is your gateway, providing secure and efficient solutions for decentralized payments. Navigate the future of digital currency with PocketPay.</p>
                                </Col>
                                <Col
                                    className="gutter-row"
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 12 }}>
                                    <div className="aboutImg">
                                        <img src={aboutSection} alt="" />
                                        <div className="playButton dFlex justifyCenter alignCenter">
                                            <span onClick={() => setShowPlayback(true)}><PlayButtonIcon /></span>
                                        </div>
                                        <div className="blurBgCircle"></div>
                                        <div className="blurShadow"></div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="logoSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Protocol Supported Chains</p>
                    </div>
                </div>
                <div className="sBody">
                    <div className="container">
                        <Carousel
                            autoplay
                            dots={false}
                            infinite
                            slidesToShow={6}
                            slidesToScroll={1}
                            speed={1000}
                            responsive={[
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 4,
                                        slidesToScroll: 1,
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    }
                                }
                            ]}
                        >
                            {logos.map((logo, index) => (
                                <div key={index} className="dFlex alignCenter justifyCenter">
                                    <img src={logo.src} alt={logo.alt} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="tokenomicsSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Tokenomics</p>
                    </div>
                    <div className="sBody">
                        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"middle"} justify={"space-between"} >
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 10 }}>
                                <div className="tokenL">
                                    <div>
                                        <img src={pieChart} alt="pie chart" />
                                    </div>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="tokenR">
                                    <div className="card mb20 dFlex alignCenter justifyBetween first">
                                        <div className="left">
                                            <p className="cText">18% <span>Sale</span></p>
                                            <span className="smHeading">4% Public-sale Price - $0.013</span>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">4,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card mb20 dFlex alignCenter justifyBetween second">
                                        <div className="left">
                                            <p className="cText">15% <span>Liquidity</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">15,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card mb20 dFlex alignCenter justifyBetween third">
                                        <div className="left">
                                            <p className="cText">24% <span>Marketing and Reward</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">24,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card mb20 dFlex alignCenter justifyBetween fourth">
                                        <div className="left">
                                            <p className="cText">10% <span>Team</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">10,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card mb20 dFlex alignCenter justifyBetween fifth">
                                        <div className="left">
                                            <p className="cText">2% <span>Advisory</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">2,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card mb20 dFlex alignCenter justifyBetween sixth">
                                        <div className="left">
                                            <p className="cText">29% <span>Ecosystem</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">29,00,00,000</span>
                                        </div>
                                    </div>
                                    <div className="card dFlex alignCenter justifyBetween seventh">
                                        <div className="left">
                                            <p className="cText">2% <span>Airdrop</span></p>
                                        </div>
                                        <div className="right">
                                            <p className="smHeading">Tokens</p>
                                            <span className="cText">2,00,00,000</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="vestingSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Vesting</p>
                    </div>
                    <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }} className="mb10">
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Public Sale</p>
                                    <span className="cText">25% TGE, 25% monthly vesting for 3 months.</span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Liquidity</p>
                                    <span className="cText">12% on TGE.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }} className="mb10">
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Marketing and Reward</p>
                                    <span className="cText">1 month lock then linear for 20 months.</span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Team</p>
                                    <span className="cText">12 months lock, linear 20 months.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }} className="mb10">
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Advisory</p>
                                    <span className="cText">6 months lock, then 15% quaterly.</span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Ecosystem</p>
                                    <span className="cText">6 month lock then linear for 24 months.</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }} justify={"start"}>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <div className="card dFlex alignCenter gap20">
                                <div className="cardIcon dFlex justifyCenter alignCenter"><RightarrowIcon /></div>
                                <div className="cardContent">
                                    <p className="cHeading">Airdrop</p>
                                    <span className="cText">1 week lock 50% Linear for 2 months(25% each).</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="participateSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Benefits of Participating</p>
                    </div>
                    <div className="sBody">
                        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} className="mb20" justify={"center"}>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <div className="card dFlex justifyStart gap20 flexCol">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><BopLockIcon /></div>
                                    <div className="cardContent">
                                        <p className="cHeading">Early Access</p>
                                        <span className="cText">Get $PAY tokens at a lower price.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <div className="card dFlex justifyStart gap20 flexCol">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><BopEventIcon /></div>
                                    <div className="cardContent">
                                        <p className="cHeading">TGE</p>
                                        <span className="cText">Less than $1m market cap at TGE</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <div className="card dFlex justifyStart gap20 flexCol">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><BopAirdropIcon /></div>
                                    <div className="cardContent">
                                        <p className="cHeading">Airdrop</p>
                                        <span className="cText">Be eligible for the Airdrop from 2% of total supply.</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} justify={"center"}>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <div className="card dFlex justifyStart gap20 flexCol">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><BopCommunityIcon /></div>
                                    <div className="cardContent">
                                        <p className="cHeading">Exclusive Community</p>
                                        <span className="cText">Join an exclusive group of early supporters with access to updates and developments.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 8 }}>
                                <div className="card dFlex justifyStart gap20 flexCol">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><BopGrowthIcon /></div>
                                    <div className="cardContent">
                                        <p className="cHeading">Potential Growth</p>
                                        <span className="cText">Benefit from the potential appreciation of $PAY tokens as our platform grows and gains more adoption.</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="featureSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Glimpse of Pocketpay</p>
                    </div>
                    <div className="sBody">
                        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"middle"}>
                            <Col
                                className="gutter-row"
                                span={6}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <div className="card dFlex flexCol alignCenter">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><FeatureInsightsIcon /></div>
                                    <p className="cText textCenter">Unlock Business Mastery with Actionable Strategic Insights</p>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                span={6}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <div className="card dFlex flexCol alignCenter">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><FeaturePOSIcon /></div>
                                    <p className="cText textCenter">Free Web POS: No Fees. Register, Get Approved, and Start Now</p>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                span={6}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <div className="card dFlex flexCol alignCenter">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><FeatureBlockchainIcon /></div>
                                    <p className="cText textCenter"> Supports variety of Blockchains you prefer</p>
                                </div>
                            </Col>
                            <Col
                                className="gutter-row"
                                span={6}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <div className="card dFlex flexCol alignCenter">
                                    <div className="cardIcon dFlex justifyCenter alignCenter"><FeatureEcoIcon /></div>
                                    <p className="cText textCenter">Native and Stables, ecosystem tokens matters, financial freedom</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <ModalComponent
                openable={showPlayback}
                closable={() => setShowPlayback(false)}
                widthStyle={768}
                footer=""
            >
                <iframe src={"https://pocketpay.finance/assets/video/pos.mp4"} width={"100%"} height={"500px"} style={{ border: 0 }} allowFullScreen />
            </ModalComponent>
        </>
    )
}

export default DashContentComponent