import { Row, Col } from "antd";
import InformationComponent from "components/InformationComponent";
import RoundsComponent from 'components/Rounds/RoundsComponent';
import ActivityComponent from 'components/ActivityComponent';

const DashDataComponent = ({ dataContent, setHaveToReload }: any) => {
    return (
        <div className="heroWrapper">
            <div className="container">
                <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align="middle" justify={"space-between"}>
                    <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 14 }}
                    >
                        <InformationComponent />
                    </Col>
                    <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        lg={{ span: 10 }}
                    >
                        <ActivityComponent dataContent={dataContent} />
                        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
                            <Col
                                className="gutter-row"
                                span={12}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                            >
                                <RoundsComponent
                                    dataContent={dataContent}
                                    setHaveToReload={setHaveToReload}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DashDataComponent