import { useEffect, useState } from "react";
import classNames from 'classnames';
import { useWallet } from "@solana/wallet-adapter-react";
import { getStorageData, setStorageData } from "utils/helper";
import { login } from "apiServices/app";
import DashDataComponent from '../DashDataComponent';
import DashContentComponent from '../DashContentComponent';
import { useStyles } from './style';

const DashboardComponent = () => {
  const wallet = useWallet();
  const { connected } = useWallet();
  const getLocalAuth = getStorageData("auth-token");

  const [haveToReload, setHaveToReload] = useState(false);
  const [dataContent, setDataContent] = useState<any>({});
  const classes = useStyles();

  const onApiLogin = (getFormData: any, getIdentify: any) => {
    try {
      login(getFormData)
        .then((response: any) => {
          const responseData = response.data || response;
          if (responseData && responseData.auth_code) {
            setStorageData("auth-token", responseData.auth_code);
            setDataContent(responseData);
            setHaveToReload(false);
            // if (getIdentify === "with-ref") {
            //   navigate("/");
            // }
          }
        })
        .catch((error: any) => {
          // console.log(error);
        });
    } catch (error) { }
  };

  const onIdentifyLogin = () => {
    try {
      const getLocalPath = window.location.pathname;
      if (getLocalPath !== "/") {
        let newSubReferral = getLocalPath.substring(1);
        onApiLogin({
          wallet_address: wallet.publicKey,
          referral_code: newSubReferral,
        }, "with-ref");
      } else {
        onApiLogin({
          wallet_address: wallet.publicKey,
        }, "normal");
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (haveToReload && connected && wallet.publicKey) {
      onIdentifyLogin();
    }
  }, [haveToReload]);

  useEffect(() => {
    if (connected && wallet.publicKey) {
      onIdentifyLogin();
    }
  }, [connected, wallet.publicKey]);

  useEffect(() => {
    if (getLocalAuth == null) {
      setDataContent({});
    }
    if (!connected && Object.keys(dataContent).length > 0) {
      setDataContent({});
    }
  }, [getLocalAuth, connected])


  return (
    <div className={classNames(classes.mainContainer)}>
      <DashDataComponent
        dataContent={dataContent}
        setHaveToReload={setHaveToReload}
      />
      <DashContentComponent />
    </div>
  );
};

export default DashboardComponent;
