import React, { useState } from "react";
import classNames from 'classnames';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepOneComponent from '../StepOneComponent';
import { useStyles } from './style';

const RoundsComponent = ({ dataContent, setHaveToReload }: any) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.roundsContainer)}>
      <ToastContainer />
      <div className="card">
        <p className="mdHeading">Investment</p>
        <StepOneComponent
          dataContent={dataContent}
          setHaveToReload={setHaveToReload}
        />
      </div>
    </div>
  );
};

export default RoundsComponent;
