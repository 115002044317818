import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  footer: {
    textAlign: "center",
    padding: "15px 0",
    "& span": {
      color: "var(--white)",
      fontWeight: "500",
    },
  },
});
