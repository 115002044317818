import React from 'react';
import classNames from 'classnames';
import { BottomarrowIcon } from 'svgIcon';
import airdrop from "assets/images/airdrop.png";
import { useStyles } from './style';
import firstStep from "assets/images/firstStep.svg";
import secondStep from "assets/images/secondStep.svg";
import thirdStep from "assets/images/thirdStep.svg";
import fourthStep from "assets/images/fourthStep.svg";
import fifthStep from "assets/images/fifthStep.svg";
import { Col, Row } from 'antd';

const ReferralProgramComponent = () => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.referralContainer)}>
            <div className="referralSection sectionSpacing">
                <div className="container">
                    <div className="airdrops">
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                        <div className="airdrop"><img src={airdrop} /></div>
                    </div>
                    <div className="banner">
                        <h2>PocketPay Referral <span>Extravaganza</span></h2>
                        <span className="cText">Join the PocketPay Referral Extravaganza and earn rewards by inviting your friends and community to participate in the future of decentralized payments! Plus, both you and your referred users can earn airdrop rewards in USDC/$PAY. </span>
                        <p className="cHeading">How it works</p>
                        <div className="cardIcon dFlex justifyCenter alignCenter"><BottomarrowIcon /></div>
                    </div>
                    <div className="sBody">
                        <div className="refSteps  dFlex  gap100 flexCol">
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">01</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Log in to the Pre-Sale Portal</p>
                                    <span className="cText">Participants need to log in to the PocketPay pre-sale portal using their registered credentials.</span>
                                </div>
                            </div>
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">02</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Access Your Unique Referral Link</p>
                                    <span className="cText">Once logged in, each participant will see a unique referral link on their dashboard. This link is your key to earning rewards.</span>
                                </div>
                            </div>
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">03</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Share Your Referral Link</p>
                                    <span className="cText">Share your unique referral link with your contacts, friends, and community. Spread the word through social media, emails, and any other platform where you can reach potential investors.</span>
                                </div>
                            </div>
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">04</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Earn Rewards</p>
                                    <span className="cText">When someone uses your referral link to log in to the pre-sale portal and makes an investment in PocketPay, you will earn 5% of their investment as a referral reward.</span>
                                    <div className="example">
                                        <p className="smHeading"><span>Example: </span>If your friend invests $1,000 using your referral link, you will receive $50 as a reward.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">05</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Generate and Share Links Freely</p>
                                    <span className="cText">There is no limit to how many referral links you can generate or share. The more people you refer, the more you can earn.</span>
                                </div>
                            </div>
                            <div className="refStep dFlex alignCenter gap30">
                                <div className="left dFlex alignCenter justifyCenter flexCol">
                                    <span className="cText">Step</span>
                                    <p className="mdHeading">06</p>
                                </div>
                                <div className="right">
                                    <p className="cHeading">Airdrop Rewards for Referred Users</p>
                                    <span className="cText">If the users you refer make an investment in the PocketPay pre-sale, they will also be eligible for an airdrop. The airdrop amount will be equivalent to the total commission you earned from your community’s investments.</span>
                                    <div className="example">
                                        <p className="smHeading"><span>Example:</span> If you earn a total of $100 in referral commissions from your community's investments, the users you referred will collectively receive $100 worth of $PPAY tokens in an airdrop.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="exampleSection sectionSpacing">
                <div className="container">
                    <div className="sHeader">
                        <p className="sTitle">Example Scenario</p>
                    </div>
                    <div className="sBody">
                        <Row align={"middle"} justify={"space-between"} className="mb50">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="steps dFlex alignStart gap30">
                                    <div className="left">
                                        <p className="lgHeading">#1</p>
                                    </div>
                                    <div className="right">
                                        <p className="cHeading mb10">John logs into the PocketPay pre-sale portal and accesses his referral link.</p>
                                        <span className="cText">John shares his unique referral link with his friend, Jane, via email.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }} className="textCenter">
                                <img src={firstStep} alt="" />
                            </Col>
                        </Row>
                        <Row align={"middle"} justify={"space-between"} className="mb50">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="steps dFlex alignStart gap30">
                                    <div className="left">
                                        <p className="lgHeading">#2</p>
                                    </div>
                                    <div className="right">
                                        <p className="cHeading mb10">Jane clicks on the referral link with referral code in URL and logs into the pre-sale portal.</p>
                                        <span className="cText">Jane decides to invest $500 in PocketPay tokens.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }} className="textCenter">
                                <img src={secondStep} alt="" />
                            </Col>
                        </Row>
                        <Row align={"middle"} justify={"space-between"} className="mb50">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="steps dFlex alignStart gap30">
                                    <div className="left">
                                        <p className="lgHeading">#3</p>
                                    </div>
                                    <div className="right">
                                        <p className="cHeading mb10">John receives 5% of Jane’s investment as a reward.</p>
                                        <span className="cText">John earns $25 from Jane’s investment.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }} className="textCenter">
                                <img src={thirdStep} alt="" />
                            </Col>
                        </Row>
                        <Row align={"middle"} justify={"space-between"} className="mb50">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="steps dFlex alignStart gap30">
                                    <div className="left">
                                        <p className="lgHeading">#4</p>
                                    </div>
                                    <div className="right">
                                        <p className="cHeading mb10">John shares his referral link on social media, and two more friends use it to invest $1,000 each.</p>
                                        <span className="cText">John earns an additional $50 + $50 = $100 from these two investments.</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }} className="textCenter">
                                <img src={fourthStep} alt="" />
                            </Col>
                        </Row>
                        <Row align={"middle"} justify={"space-between"} className="mb50">
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <div className="steps dFlex alignStart gap30">
                                    <div className="left">
                                        <p className="lgHeading">#5</p>
                                    </div>
                                    <div className="right">
                                        <p className="cHeading mb10">Airdrop to Referred Users</p>
                                        <span className="cText">The users John referred (Jane and his two other friends) will collectively receive an airdrop worth $125 (equivalent to John’s total referral commission).</span>
                                    </div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }} className="textCenter">
                                <img src={fifthStep} alt="" />
                            </Col>
                        </Row>
                        <div className="egBottom">
                            <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"middle"} justify={"space-between"} >
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}>
                                    <div className="card dFlex justifyCenter alignCenter flexCol gap10">
                                        <span className="cHeading">Total Rewards Earned by John</span>
                                        <p className="lgHeading">$25 + $50 + $50 = <span>$125</span></p>
                                    </div>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: 12 }}>
                                    <div className="card dFlex justifyCenter alignCenter flexCol gap10">
                                        <span className="cHeading">Total Airdrop to Refer Users</span>
                                        <p className="lgHeading"><span>$125</span></p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="egNote">
                                <p className="smHeading"><span>AIRDROP: </span>All the pre-sale participants are eligible from the 2% Airdrop pool.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralProgramComponent